<template>
  <div class="box">
    <p class="infoText" style="margin-top:1rem;">您的需求已提交，我们将尽快与您联系。</p>
    <p class="infoText">如需快速推荐</p>
    <p class="infoText">请识别下方二维码添加选品官</p>
    <!-- <img src="../../assets/img/ercode.png" alt="" class="erImg"> -->
    <img src="../../assets/img/ercode1.png" alt="" class="erImg">
    <van-button type="info" size="normal" color="#048DAD" class="homeBtn" @click="toHome">返回首页</van-button>
  </div>
</template>
<script>
  export default {
    data() {
      return {

      }
    },
    created() {

    },
    methods: {
      // toOtherPage() {
      //   wx.config({
      //     debug: true,
      //     appId: 'wxf87ee20805c6c944', // 和获取Ticke的必须一样------必填，公众号的唯一标识
      //     timestamp: this.dataSign.timestamp, // 必填，生成签名的时间戳
      //     noncestr: this.dataSign.noncestr, // 必填，生成签名的随机串
      //     signature: this.dataSign.sign, // 必填，签名，见附录1
      //     // 需要分享的列表项:发送给朋友，分享到朋友圈，分享到QQ，分享到QQ空间
      //     jsApiList: [
      //     'onMenuShareTimeline', 'onMenuShareAppMessage', 'onMenuShareQQ', 'onMenuShareWeibo',
      //     'onMenuShareQZone', 'previewImage', 'hideOptionMenu', 'showOptionMenu','chooseWXPay',
      //     'chooseImage','openLocation','openAddress','checkJsApi','updateAppMessageShareData','updateTimelineShareData'
      //     ],
      //     openTagList: ['wx-open-launch-weapp']
      //   })
      // },
      toHome() {
        wx.miniProgram.navigateBack({
          delta: 1
        })
      }
    },
  }
</script>

<style scoped>
  .box {
    /* background-image: url('../../assets/img/baground.png'); */
    background-image: url('../../assets/img/baground1.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-color: #15181F;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
  }

  .infoText {
    font-size: .16rem;
    text-align: center;
    margin-top: .1rem;
    letter-spacing: 1px;
  }

  .erImg {
    width: 1.5rem;
    height: 1.5rem;
    display: block;
    margin: .4rem auto;
  }

  .homeBtn {
    width: 60%;
    display: block;
    margin: .2rem auto;
    font-size: .16rem;
    height: .4rem;
    border-radius: .05rem;
  }
</style>
